import $ from 'jquery'
export default function floating() {
  const floatArea =  $('.js__floating, #content_bottom_fixed')
  if (floatArea.length > 0) {
    const floatHeight = floatArea.outerHeight()
    let getScrollCount = () => {
      return (window.pageYOffset !== undefined) ? window.pageYOffset : document.documentElement.scrollTop;
    }
    $('.m__footer__copyright').css('padding-bottom', floatHeight)
    $(window).on('load scroll', () => {
      let scroll = getScrollCount()
      if (scroll > 300) {
        floatArea.addClass('show')
      } else {
        floatArea.removeClass('show')
      }
    })
  }
}
