import $ from 'jquery'
export default function pageScroll() {
  $('a[href^="#"]').on('click', (e) => {
    const speed = 500
    const href = e.currentTarget.hash
    const target = $(href === '#' || href === '' ? 'html' : href)
    const headerHeight = $('.m__header').height()
    const floatBtnAreaHeight = $('.m__fair__float__btn-area').height() || 0 // .m__fair__float__btn-areaの高さが存在しない場合は0とする
    const totalHeight = headerHeight + floatBtnAreaHeight // .m__headerと.m__fair__float__btn-areaの高さを合計する
    const position = target.offset().top - totalHeight
    $('html, body').animate({ 'scrollTop': position }, speed, 'swing')
    return false
  })

  const id = location.hash
  if (id) {
    const headerHeight = $('.m__header').height()
    const floatBtnAreaHeight = $('.m__fair__float__btn-area').height() || 0 // .m__fair__float__btn-areaの高さが存在しない場合は0とする
    const totalHeight = headerHeight + floatBtnAreaHeight // .m__headerと.m__fair__float__btn-areaの高さを合計する
    if ('' != id) {
      const position = $(id).offset().top - totalHeight;
      $('html').animate({ scrollTop: position }, 0);
    }
  }
}
