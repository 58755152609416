import $ from 'jquery'
export default function toggleAccorion() {
  const breakPoint = 736
  const windowWidth = window.innerWidth
  $('.js__accordion-trigger').on('click', () => {
    const target = event.currentTarget
    if (windowWidth < breakPoint) {
      $(target).toggleClass('open');
      $(target).siblings('.js__accordion-target').stop().slideToggle();
    }
  })
  $('.js__accordion-close').on('click', () => {
    const target = event.currentTarget
    if (windowWidth < breakPoint) {
      $(target).parents('.js__accordion-target').stop().slideToggle().prev('.js__accordion-trigger').toggleClass('open')
    }
  })
  $('.js__more-conditions-trigger').on('click', () => {
    const target = event.currentTarget
    $(target).toggleClass('open');
    $('.js__more-conditions-target').stop().slideToggle();
  })
  $('.js__more-content-trigger').on('click', () => {
    const target = event.currentTarget
    $(target).hide();
    $('.js__more-content-target').slideDown();
  })
  $('.js__balloon-trigger').on('click', () => {
    const target = event.target
    if ($(target).hasClass('open')) {
      $(target).removeClass('open');
      $(target).siblings('.js__balloon-target').stop().fadeOut();
    } else {
      $('.js__balloon-trigger').removeClass('open');
      $('.js__balloon-target').stop().hide();
      $(target).addClass('open');
      $(target).siblings('.js__balloon-target').stop().fadeIn();
    }
  })
  $('.js__under-accordion-trigger').on('click', () => {
    const target = event.currentTarget
    //if (windowWidth < breakPoint) {
      $(target).toggleClass('open').prev('.js__under-accordion-target').stop().slideToggle()
    //}
  })
  const accordionTitle = document.querySelectorAll('.m__common__accordion--title, .js__common__accordion');
  function toggle() {
    const content = this.nextElementSibling;
    this.classList.toggle('open');
    content.classList.toggle('open');
  }

  for (let i = 0; i < accordionTitle.length; i++) {
    accordionTitle[i].addEventListener('click', toggle)
  }
  const slideSpeed = 300
  $('.aco_btn').on('click', () => {
    const target = event.currentTarget
    $(target).next('.aco_body').slideToggle(slideSpeed)
    $(target).toggleClass('open')
  })

  $('.js__event-info-trigger').on('click', (e) => {
    const target = event.currentTarget
    $(target).toggleClass('open');
    $(target).next('.js__event-info-target').stop().slideToggle();
  })
}