import $ from 'jquery'
$(function () {
  $('.openModal').click(function(e){
    const target = e.currentTarget.dataset.modal;
    if (target) {
      $(`.modalArea[data-modal="${target}"]`).fadeIn();
    } else {
      $('.modalArea').fadeIn();
    }
  });
  $('.closeModal , .modalBg').click(function(){
    $('.modalArea, .programModalArea').fadeOut();
    if ($('.imageModalWrap').length > 0) {
      setTimeout(function() {
        $('.imageModalWrap img').attr('src', '')
      }, 500)
    }
  });
  $('.imageModal').on('click', function() {
    const src = $(this).attr('href')
    $('.imageModalWrap img').attr('src', src)
    return false
  })

  // 病院情報詳細の新専門医、後期研修タブをクリックした際のモーダル処理
  $('.programOpenModal').on('click', function() {
    $('.programModalArea').fadeIn()
    // 新専門医と後期研修の出力を分ける対応（開発実装時不要であれば削除してください）
    if ($(this).hasClass('latter')) {
      $('.program-list.latter').show()
      $('.program-list.specialist').hide()
    } else if ($(this).hasClass('specialist')) {
      $('.program-list.specialist').show()
      $('.program-list.latter').hide()
    }
    return false
  });
});
