import $ from 'jquery'
export default function gotoPagetop() {
  let getScrollCount = () => {
    return (window.pageYOffset !== undefined) ? window.pageYOffset : document.documentElement.scrollTop;
  }
  const pagetopBtn = $('#js-pagetop-btn')
  const floatArea = $('.js__floating')
  $(window).on('load scroll', () => {
    const windowHeight = window.innerHeight,
     windowWidth = window.innerWidth,
     pageHeight = document.body.clientHeight,
     footerHeight = $('.m__footer').outerHeight()
    let fixedPoint
    if (floatArea.length > 0) {
      const floatHeight = floatArea.outerHeight()
      if (windowWidth > 768) {
        fixedPoint = pageHeight - windowHeight - footerHeight + 20 + floatHeight
      } else {
        fixedPoint = pageHeight - windowHeight - footerHeight + 63 + floatHeight
      }
    } else {
      if (windowWidth > 768) {
        fixedPoint = pageHeight - windowHeight - footerHeight + 20
      } else {
        fixedPoint = pageHeight - windowHeight - footerHeight + 63
      }
    }

    let scroll = getScrollCount()
    if (scroll > 300) {
      pagetopBtn.addClass('is-fadein')
      if (floatArea.length > 0) {
        const floatHeight = floatArea.outerHeight()
        pagetopBtn.css('transform', 'translateY(-' + floatHeight + 'px)')
      }
    } else {
      pagetopBtn.removeClass('is-fadein')
    }
    if (scroll > fixedPoint) {
      pagetopBtn.addClass('is-fixed')
      pagetopBtn.css('transform', 'translateY(0)')
    } else {
      pagetopBtn.removeClass('is-fixed')
      if (floatArea.length > 0) {
        const floatHeight = floatArea.outerHeight()
        pagetopBtn.css('transform', 'translateY(-' + floatHeight + 'px)')
      }
    }
  })
  let scrollToTop = () => {
    $('body,html').animate({scrollTop: 0}, 500);
  }
  pagetopBtn.on('click', () => {
    scrollToTop()
  })
}
