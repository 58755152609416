export default function viewport() {
  const ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
    const viewport = document.querySelector('meta[name="viewport"]')
    if (viewport) {
      const viewportContent = viewport.getAttribute('content')
      viewport.setAttribute('content', viewportContent + ', user-scalable=no')
    }
  }
}
