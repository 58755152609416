import gotoPagetop from './go-to-pagetop'
import pageScroll from './scroll'
import toggleAccorion from './accordion'
import floating from './float'
import viewport from './viewport'
import '../modules/flatpickr'
import './modal'
import '../modules/closest'
import '../modules/forEach'
import '../modules/css_browser_selector'

window.addEventListener('load', () => {
  gotoPagetop()
  pageScroll()
  toggleAccorion()
  floating()
  viewport()
})
