import flatpickr from 'flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja.js'

import 'flatpickr/dist/flatpickr.min.css'

flatpickr.localize(Japanese)

window.addEventListener('DOMContentLoaded', () => {
  flatpickr(".js-date-picker", {})
  flatpickr(".js-datetime-picker", { enableTime: true })
  flatpickr(".js-time-picker", {enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true})
})
